// export const URL = "http://127.0.0.1:8000";
// export const URL = "https://sinergiamedica.icu/server";
export const URL = "https://sinergiamedica.club/server";

export const URL_API = URL + "/api";
export const URL_PDF = URL + "/pdf";
export const URL_DOCUMENTS = URL + "/documents/download";

export const headersToken = (token) => {
  return {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const headers = () => {
  return {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  };
};

export const toFormData = (values) => {
  let data = new FormData();

  for (var key in values) {
    if (
      typeof values[key] === "object" &&
      values[key] !== null &&
      Array.isArray(values[key])
    ) {
      data.append(key, JSON.stringify(values[key]));
    } else {
      data.append(key, values[key]);
    }
    if (key == "documents") {
      for (var i = 0; i < values.documents.length; i++) {
        for (var document_key in values.documents[i]) {
          if (document_key === "file")
            data.append(
              "document_file_" + i,
              values.documents[i][document_key]
            );
        }
      }
      data.append("total_documents", values.documents.length);
    }
  }

  return data;
};

export const msgAlert = (type, msg) => {
  return {
    showConfirmButton: false,
    icon: type,
    text: msg,
    timer: 4000,
  };
};

export const msgConfirm = (msg) => {
  return {
    showCancelButton: true,
    cancelButtonText: "No",
    confirmButtonText: "Sí",
    icon: "warning",
    text: msg,
  };
};

export const msgConfirmCancel = (msg) => {
  return {
    showCancelButton: true,
    cancelButtonText: "Cancelar",
    confirmButtonText: "Sí",
    showDenyButton: true,
    denyButtonText: `No`,
    icon: "warning",
    text: msg,
  };
};
export const validateHour = (time) => {
  const hours = time.split(":")[0];
  const minutes = time.split(":")[1];
  if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
    this.$swal.fire(msgAlert("error", "Formato de hora incorrecto"));
    console.log("Error");
  }
};
export const dateTimeNow = () => {
  let dt = new Date();

  return `${dt
    .getFullYear()
    .toString()
    .padStart(4, "0")}-${(dt.getMonth() + 1).toString().padStart(2, "0")}-${dt
    .getDate()
    .toString()
    .padStart(2, "0")} ${dt
    .getHours()
    .toString()
    .padStart(2, "0")}:${dt
    .getMinutes()
    .toString()
    .padStart(2, "0")}:${dt
    .getSeconds()
    .toString()
    .padStart(2, "0")}`;
};

export const base64ToArrayBuffer = (base64) => {
  const binary_string = window.atob(base64.replace(/\s/g, ""));
  const len = binary_string.length;
  let bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }

  return bytes.buffer;
};

export const dateTimeToFile = () => {
  let dt = new Date();

  return `${dt
    .getFullYear()
    .toString()
    .padStart(4, "0")}${(dt.getMonth() + 1).toString().padStart(2, "0")}${dt
    .getDate()
    .toString()
    .padStart(2, "0")}${dt
    .getHours()
    .toString()
    .padStart(2, "0")}${dt
    .getMinutes()
    .toString()
    .padStart(2, "0")}${dt
    .getSeconds()
    .toString()
    .padStart(2, "0")}`;
};

export const rules = () => {
  return {
    name: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length <= 95) || "Máximo 95 caracteres.",
    ],
    password: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length >= 8) || "Mínimo 8 caracteres.",
      (v) => (v && v.length <= 20) || "Máximo 20 caracteres.",
      (v) => /(?=.*[A-Z])/.test(v) || "Al menos una mayúscula.",
      (v) => /(?=.*[a-z])/.test(v) || "Al menos una minúscula.",
      (v) => /(?=.*\d)/.test(v) || "Al menos un número.",
      (v) =>
        /([!@$%*])/.test(v) || "Al menos un caractere especial (! @ $ % *).",
    ],
    passwordLogin: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length >= 6) || "Mínimo 6 caracteres.",
    ],
    email: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length <= 50) || "Máximo 50 caracteres.",
      (v) => /.+@.+\..+/.test(v) || "Formato invalido.",
    ],
    emailNoRequired: [
      (v) => {
        if (v) return (v && v.length <= 50) || "Máximo 50 caracteres.";
        else return true;
      },
      (v) => {
        if (v) return /.+@.+\..+/.test(v) || "Formato invalido.";
        else return true;
      },
    ],
    text250Required: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length <= 250) || "Máximo 250 caracteres.",
    ],
    text250: [
      (v) => {
        if (v) return (v && v.length <= 250) || "Mínimo 250 caracteres.";
        else return true;
      },
    ],
    text150Required: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length <= 150) || "Máximo 150 caracteres.",
    ],
    text150: [
      (v) => {
        if (v) return (v && v.length <= 150) || "Mínimo 150 caracteres.";
        else return true;
      },
    ],
    text100Required: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length <= 100) || "Máximo 100 caracteres.",
    ],
    text100: [
      (v) => {
        if (v) return (v && v.length <= 100) || "Mínimo 100 caracteres.";
        else return true;
      },
    ],
    text50Required: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length <= 50) || "Máximo 50 caracteres.",
    ],
    text50: [
      (v) => {
        if (v) return (v && v.length <= 50) || "Mínimo 50 caracteres.";
        else return true;
      },
    ],
    text20Required: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length <= 20) || "Máximo 20 caracteres.",
    ],
    text20: [
      (v) => {
        if (v) return (v && v.length <= 20) || "Mínimo 20 caracteres.";
        else return true;
      },
    ],
    text30Required: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length <= 30) || "Máximo 30 caracteres.",
    ],
    text30: [
      (v) => {
        if (v) return (v && v.length <= 30) || "Mínimo 30 caracteres.";
        else return true;
      },
    ],
    text25Required: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length <= 25) || "Máximo 25 caracteres.",
    ],
    text25: [
      (v) => {
        if (v) return (v && v.length <= 25) || "Mínimo 25 caracteres.";
        else return true;
      },
    ],
    text18Required: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length <= 18) || "Máximo 18 caracteres.",
    ],
    text18: [
      (v) => {
        if (v) return (v && v.length == 18) || "Tamaño 18 caracteres.";
        else return true;
      },
    ],
    text15Required: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length <= 15) || "Máximo 15 caracteres.",
    ],
    text15: [
      (v) => {
        if (v) return (v && v.length <= 15) || "Mínimo 15 caracteres.";
        else return true;
      },
    ],
    text10Required: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length <= 10) || "Máximo 10 caracteres.",
    ],
    text10: [
      (v) => {
        if (v) return (v && v.length <= 10) || "Mínimo 10 caracteres.";
        else return true;
      },
    ],
    text5Required: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length <= 5) || "Máximo 5 caracteres.",
    ],
    text5: [
      (v) => {
        if (v) return (v && v.length <= 5) || "Mínimo 5 caracteres.";
        else return true;
      },
    ],
    text3Required: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length <= 3) || "Máximo 3 caracteres.",
    ],
    text3: [
      (v) => {
        if (v) return (v && v.length >= 1) || "Mínimo 1 caracteres.";
        else return true;
      },
      (v) => {
        if (v) return (v && v.length <= 3) || "Mínimo 3 caracteres.";
        else return true;
      },
    ],
    code_tax: [
      (v) => {
        if (v) return (v && v.length >= 12) || "Mínimo 12 caracteres.";
        else return true;
      },
      (v) => {
        if (v) return (v && v.length <= 13) || "Mínimo 13 caracteres.";
        else return true;
      },
    ],
    code_tax_sat: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length <= 13) || "Máximo 13 caracteres.",
      (v) => /^[A-Za-zñÑ&]{3,4}\d{6}\w{3}$/.test(v) || "Formato invalido.",
    ],
    code_tax_sat_opt: [
      (v) => {
        if (v) return (v && v.length <= 13) || "Máximo 13 caracteres.";
        else return true;
      },
      (v) => {
        if (v)
          return /^[A-Za-zñÑ&]{3,4}\d{6}\w{3}$/.test(v) || "Formato invalido.";
        else return true;
      },
    ],
    hour: [
      (v) => {
        if (v) return (v && v.length === 8) || "8 caracteres.";
        else return true;
      },
      (v) => {
        if (v) return /.+:.+:+./.test(v) || "Formato invalido.";
        else return true;
      },
    ],
    hourRequired: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length === 8) || "8 caracteres.",
      (v) => /.+:.+/.test(v) || "Formato invalido.",
    ],
    code_taxRequired: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length >= 12) || "Mínimo 12 caracteres.",
      (v) => (v && v.length <= 13) || "Máximo 13 caracteres.",
    ],
    required: [(v) => !!v || "Campo requerido."],
    requiredNotNull: [(v) => v !== null || "Campo requerido no nulo."],
    fileLmtReq: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.size <= 786432) || "El tamaño máximo de carga es de 768kB",
    ],
    fileLmt: [
      (v) => {
        if (v)
          return (
            (v && v.size <= 786432) || "El tamaño máximo de carga es de 768kB"
          );
        else return true;
      },
    ],
    textRequired: [
      (v) => !!v || "Campo requerido.",
      (v) => /^[A-Za-z ]+$/.test(v) || "Formato invalido, solo letras.",
    ],
    text: [
      (v) => {
        if (v)
          return /^[A-Za-z ]+$/.test(v) || "Formato invalido, solo letras.";
        else return true;
      },
    ],
    numberRequired: [
      (v) => !!v || "Campo requerido.",
      (v) => /^[0-9]+$/.test(v) || "Formato invalido, solo números.",
    ],
    number: [
      (v) => {
        if (v) return /^[0-9]+$/.test(v) || "Formato invalido, solo números.";
        else return true;
      },
    ],
    fileRequired800kB: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.size < 819200) || "El tamaño máximo de carga es de 800kB",
    ],
    fileNotRequired800kB: [
      (v) => {
        if (v)
          return (
            (v && v.size < 819200) || "El tamaño máximo de carga es de 800kB"
          );
        else return true;
      },
    ],
  };
};

export const numberFormat = (val) => {
  return Number(parseFloat(val).toFixed(2)).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};
